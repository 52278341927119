export function updateBodyClass (route) {
  // Remove any existing route-related class from body
  document.body.className = document.body.className.replace(/\broute-\S+/g, '')

  // console.log('ROUTE', route)

  // Add the new class based on the route name
  if (route.name) {
    document.body.classList.add(`route-${route.name.toLowerCase()}`)
  }
}
