import * as Sentry from '@sentry/vue'
import { createStore } from 'vuex'
import axios from 'axios'
import i18n from '@/utils/i18n'
import moment from 'moment'
moment.locale('it')
i18n.global.locale.value = 'it'

// ---INITIAL STATE
const getDefaultState = () => {
  return {
    user: null,
    userData: null,
    status: 'hide',
    localVersion: null,
    desiredVersion: null
  }
}

let loaded = false
const prefix = process.env.VUE_APP_NAME

const store = createStore({
  state: getDefaultState(),
  // ---GETTERS
  getters: {
    isAuthenticated: state => !!state.user,
    stateUser: state => state.user,
    userData: state => state.userData,
    contestStatus: state => state.status,
    getDesiredVersion: (state) => {
      return state.desiredVersion
    },
    getLocalVersion: (state) => {
      return state.localVersion
    }
  },

  // ---MUTATIONS
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setUser (state, user) {
      state.user = user
      if (user) sessionStorage.setItem(prefix + 'store', JSON.stringify(user))
      else sessionStorage.removeItem(prefix + 'store')
    },
    setUserData (state, userData) {
      state.userData = userData
      if (userData) sessionStorage.setItem(prefix + 'currentUserData', JSON.stringify(userData))
      else sessionStorage.removeItem(prefix + 'currentUserData')
    },
    setContestStatus (state, status) {
      state.status = status
    },
    updateLocalVersion (state, version) {
      state.localVersion = version
    },
    updateDesiredVersion (state, version) {
      console.log('---Current Version: ', state.localVersion, ' ---Online Version: ', version)
      state.desiredVersion = version
    }
  },

  // ---ACTIONS
  actions: {
    resetStore ({ commit }) {
      commit('resetState')
    },
    // --Get logs
    // async getHistory({ state }, phone, offset, limit) {
    //   const data = state.user
    //   const off = offset || 0
    //   const lim = limit || 10000
    //   let rsp
    //   if (data) {
    //     const Authorization = data.token_type + ' ' + data.access_token
    //     rsp = await axios.get('/logs/?skip=' + off + '&limit=' + lim, {
    //       headers: {
    //         Authorization
    //       }
    //     })
    //       .then((rsp) => {
    //         let logs = rsp.data
    //         // console.log(logs)
    //         // -Filter by PHONE
    //         if (phone) {
    //           logs = logs.filter((el) => {
    //             const currentNumber = el.extra_data.phone_number
    //             const thirtynined = '+39' + phone
    //             const thirtyninedCurrent = '+39' + currentNumber
    //             return currentNumber === phone || currentNumber === thirtynined || phone === thirtyninedCurrent
    //           })
    //         }

    //         for (let i = 0; i < logs.length; i++) {
    //           const offset = moment().utcOffset()
    //           const temp = moment.parseZone(logs[i].created_at).utcOffset(offset).format('lll')
    //           logs[i].created_at = temp
    //         }
    //         // -Filter by DESCRIPTION
    //         // if (desc) {

    //         // }
    //         return logs
    //       })
    //       .catch((e) => {
    //         return fireError(e)
    //       })
    //   } else {
    //     console.error('NOT AUTHENTICATED', store.user)
    //     rsp = false
    //   }
    //   return rsp
    // },
    // --Send Mail Report
    // async sendMail({ state }, payload) {
    //   const data = state.user
    //   const Authorization = data.token_type + ' ' + data.access_token
    //   const rsp = await axios.post('/utils/export-items/', payload, {
    //     headers: {
    //       Authorization
    //     }
    //   })
    //     .then((rsp) => {
    //       // console.log('---SEND MAIL', rsp)
    //       return rsp
    //     })
    //     .catch((e) => {
    //       return fireError(e)
    //     })
    //   return rsp
    // },
    // --Campaign Stats
    // async getStats({ state }) {
    //   const data = state.user
    //   if (data) {
    //     const Authorization = data.token_type + ' ' + data.access_token
    //     return await axios.get('/utils/stats', {
    //       headers: {
    //         Authorization
    //       }
    //     })
    //       .then((rsp) => {
    //         return rsp.data
    //       })
    //       .catch((e) => {
    //         return fireError(e)
    //       })
    //   }
    // },
    // --Get website version
    async getWebsiteVersion () {
      return await axios.get('/utils/ui-version')
        .then((rsp) => {
          return rsp.data.ui_version
        })
        .catch((e) => {
          return fireError(e)
        })
    },
    // --Create Item
    async playCode ({ state }, payload) {
      const data = state.user
      if (data) {
        const Authorization = data.token_type + ' ' + data.access_token
        return await axios.post('/items/', payload, {
          headers: {
            Authorization
          }
        })
          .then((rsp) => {
            return rsp.data
          })
          .catch((e) => {
            return fireError(e)
          })
      }
    },
    async getPartecipations ({ state }) {
      const data = state.user
      if (data) {
        const Authorization = data.token_type + ' ' + data.access_token
        return await axios.get('/items/', {
          headers: {
            Authorization
          }
        })
          .then((rsp) => {
            return rsp.data
          })
          .catch((e) => {
            return fireError(e)
          })
      }
    },
    // --Login
    async logIn ({ commit }, data) {
      const rsp = await axios.post('/login/access-token', data)
        .then((rsp) => {
          commit('setUser', rsp.data)
          return rsp.data
        })
        .catch((e) => {
          return fireError(e)
        })
      return rsp
    },
    // --Get my infos
    async getUserInfo ({ state, commit }) {
      const data = state.user
      if (data) {
        const Authorization = data.token_type + ' ' + data.access_token
        const rsp = await axios.get('/users/me', {
          headers: {
            Authorization
          }
        })
          .then((rsp) => {
            commit('setUserData', rsp.data)
            return rsp.data
          })
          .catch((e) => {
            return fireError(e)
          })

        return rsp
      }
    },
    // --Registration
    async registration ({ commit }, data) {
      // console.log('LOGIN DATA', data.get('username'))
      const rsp = await axios.post('/users/', data)
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          return fireError(e)
        })
      return rsp
    },
    // --Registration confirm
    async confirmUser ({ commit }, payload) {
      const rsp = await axios.post('/users/activate', payload)
        .then((rsp) => {
          commit('setUser', rsp.data)
          return rsp
        })
        .catch((e) => {
          return fireError(e)
        })
      return rsp
    },
    // --Update user
    async updateUser ({ state }, payload) {
      const data = state.user
      if (data) {
        const Authorization = data.token_type + ' ' + data.access_token
        const rsp = await axios.put('/users/me', payload, {
          headers: {
            Authorization
          }
        })
          .then((rsp) => {
            return rsp
          })
          .catch((e) => {
            return fireError(e)
          })
        return rsp
      }
    },
    // --Recover password
    async recoverPassword ({ state }, payload) {
      const rsp = await axios.post('/password-recovery/' + payload.email)
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          return fireError(e)
        })
      return rsp
    },
    // --Reset password
    async resetPassword ({ state }, payload) {
      const rsp = await axios.post('/reset-password/', payload)
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          return fireError(e)
        })
      return rsp
    },
    // --Logout
    logout ({ commit }) {
      commit('setUser', null)
      commit('setUserData', null)
    },
    // --Check contest dates
    // checkContestStatus ({ commit }) {
    //   const start = process.env.VUE_APP_CAMPAIGN_START
    //   const end = process.env.VUE_APP_CAMPAIGN_END
    //   const endSite = process.env.VUE_APP_SITE_END

    //   const nowDate = moment()
    //   const campaignStart = moment(start)
    //   const campaignEnd = moment(end)
    //   const siteEnd = moment(endSite)

    //   console.log('---TODAY', nowDate)
    //   console.log('---START', campaignStart)
    //   console.log('---END', campaignEnd)
    //   console.log('---SITE END', siteEnd)

    //   if (nowDate >= campaignStart && nowDate < campaignEnd) commit('setContestStatus', 'campaign')
    //   else if (nowDate >= campaignEnd && nowDate < siteEnd) commit('setContestStatus', 'endCampaign')
    //   else commit('setContestStatus', 'hide')
    // },
    async initializeStore ({ commit }) {
      if (!loaded) {
        if (sessionStorage.getItem(prefix + 'store')) {
          commit('setUser', JSON.parse(sessionStorage.getItem(prefix + 'store')))
          if (sessionStorage.getItem(prefix + 'currentUserData')) {
            commit('setUserData', JSON.parse(sessionStorage.getItem(prefix + 'currentUserData')))
          } else {
            await this.dispatch('getUserInfo')
          }
          // state.mail = sessionStorage.getItem(prefix + 'currentMail')
        }
        // this.dispatch('checkContestStatus')
        loaded = true
      }
    }
  },

  // ---MODULES
  modules: {}
})

store.subscribe((mutation, state) => {
  if (state.user) sessionStorage.setItem(prefix + 'store', JSON.stringify(state.user))
  else sessionStorage.removeItem(prefix + 'store')

  if (state.userData) sessionStorage.setItem(prefix + 'currentUserData', JSON.stringify(state.userData))
  else sessionStorage.removeItem(prefix + 'currentUserData')
  // if (state.mail) sessionStorage.setItem(prefix + 'currentMail', state.mail)
  // else sessionStorage.removeItem(prefix + 'currentMail')
})

function fireError (err) { // ---ERROR MANAGER
  Sentry.captureException(err)
  console.log('DEBUG ERR', err.response)
  const detail = err?.response?.data?.detail
  if (detail === undefined || typeof detail === 'string') {
    return {
      detail: [err],
      msg_translated: 'Errore generico'
    }
  }
  if (detail === 'api.disabled') {
    return {
      detail: [err],
      msg_translated: 'Concorso NON attivo'
    }
  }
  const error = err.response.data.detail.reduce((a, v) => {
    if (v.msg.startsWith('Email not valid The domain name')) {
      return a + 'Email non valida: dominio inesistente.<br/><hr/>'
    } else if (v.msg.startsWith('IBAN not valid')) {
      return a + 'Codice IBAN non valido<br/><hr/>'
    } else {
      return a + i18n.global.t(v.msg) + '<br/><hr/>'
    }
  }, '')
  err.response.data.msg_translated = error
  return err.response.data
}

export default store
