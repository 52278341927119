<template>
  <header id="main-header">
    <transition appear @before-enter="beforeEnter" @enter="enter">
      <h2>
        <router-link :to="getRoute()" v-if="currentRouteName !== 'ComeFunziona' && currentRouteName !== 'NotFound'">
          <img src="@/assets/img/cashback/GTEL_logo_genertellife_RGB_bianco.png" />
        </router-link>
      </h2>
    </transition>
    <!-- <a class="outside-link" href="https://www.genertellife.it/landingpage/lp-valory/" target="_blank">
      Maggiori informazioni »
    </a> -->
  </header>
  <transition name="alert">
    <aside class="main-alert" :class="{error : alertError}" v-if="alertMsg" v-html="alertMsg" />
  </transition>

  <router-view v-slot="{ Component }" v-if="!loading">
    <transition name="page">
      <component :is="Component" />
    </transition>
  </router-view>

  <footer id="main-footer">
    <figure class="hidden lg:block lg:w-1/3">
      <img src="@/assets/img/cashback/flowers.png" class="pr-10" />
    </figure>
    <small class="lg:w-1/3 pb-4" v-if="currentRouteName !== 'ComeFunziona' && currentRouteName !== 'NotFound'">
      Copyright © 2022 - Promotica S.p.A. - P.IVA e C.F.: 02394460980
      Sede in via Generale dalla Chiesa, 1 Desenzano del Garda (BS)
      Reg. Imp. Brescia n. 02394460980 - Capitale sociale: 800.000 €
      per info:<br /><a href="mailto:segreteria@cashbackgenertellife.it"
        target="_blank">segreteria@cashbackgenertellife.it</a>
    </small>
    <ul class="lg:w-1/3 footer-menu pb-3" v-if="currentRouteName !== 'ComeFunziona' && currentRouteName !== 'NotFound'">
      <!-- <li><a href="#" target="_blank">Privacy</a></li> -->
      <!-- <li><a href="/termini_condizioni.pdf" target="_blank">Termini e Condizioni dal 18/10/2022 al 18/11/2022</a></li>
      <li><a href="/termini_condizioni_2.pdf" target="_blank">Termini e Condizioni dal 19/11/2022 al 30/11/2022</a></li>
      <li><a href="/termini_condizioni_3.pdf" target="_blank">Termini e Condizioni dal 01/12/2022 al 14/12/2022</a></li> -->
      <!-- <li><a href="/termini_condizioni_4.pdf" target="_blank">Termini e Condizioni dal 01/03/2023 al 31/05/2023</a></li>
      <li><a href="/termini_condizioni_5.pdf" target="_blank">Termini e Condizioni dal 08/06/2023 al 31/07/2023</a></li> -->
      <li><a href="/termini_condizioni_2024_1.pdf" target="_blank">Termini e Condizioni dal 01/02/2024 al 07/04/2024</a>
      </li>
      <!-- <li> -->
      <!-- <router-link to="/cookies">Cookies</router-link> -->
      <!-- <a href="https://www.genertellife.it/strumento-gestione-cookie-Genertellife/" target="_blank">Cookies</a> -->
      <!-- </li> -->
    </ul>
  </footer>
</template>

<script>
import { useStore, mapGetters } from 'vuex'
import gsap from 'gsap'
// import iubenda from '@/utils/iubenda.js'

export default {
  setup () {
    const store = useStore()
    store.dispatch('initializeStore')
    const beforeEnter = (el) => {
      gsap.set(el, {
        opacity: 0,
        x: '-=10'
      })
    }
    const enter = (el) => {
      gsap.to(el, {
        delay: 0.4,
        duration: 1,
        opacity: 1,
        x: '0'
      })
    }
    return {
      beforeEnter,
      enter
    }
  },
  data () {
    return {
      anim: null,
      alertMsg: null,
      alertError: null,
      loading: true
    }
  },
  computed: {
    ...mapGetters([
      'getLocalVersion',
      'getDesiredVersion'
    ]),
    currentRouteName () {
      return this.$router.currentRoute.value.name
    }
  },
  methods: {
    getRoute () {
      if (this.$store.state.user && this.$store.state.user.access_token) return '/?token=' + this.$store.state.user.access_token
      return '/'
    },
    triggerAnim (boolean) {
      if (boolean) this.anim = 'pulse'
      else this.anim = null
    },
    async checkDesiredVersion () {
      await this.$store.dispatch('getWebsiteVersion')
        .then((rsp) => {
          if (rsp && rsp > this.getLocalVersion) {
            console.warn('VERSION is going to be updated', this.getLocalVersion + ' ---> ' + rsp)
            setTimeout(() => {
              console.warn('VERSION is going to be updated', this.getLocalVersion + ' ---> ' + rsp)
              this.$router.go()
            }, 4000)
          }
          this.$store.commit('updateDesiredVersion', rsp)
        })
        .catch((err) => {
          console.error('GET WEBSITE VER', err)
        })
    }
  },
  async mounted () {
    // console.log('---API BASEURL', this.axios.defaults.baseURL)
    this.$store.commit('updateLocalVersion', process.env.VUE_APP_VER ? process.env.VUE_APP_VER : 1)
    await this.checkDesiredVersion()
    // setInterval(() => {
    //   this.checkDesiredVersion()
    // }, 300000)

    this.emitter.on('fireAlert', (data) => {
      this.alertMsg = data.msg
      this.alertError = data.error
      setTimeout(() => {
        this.alertMsg = null
        this.alertError = null
      }, 6000)
    })
    // iubenda()
    this.loading = false
  }
}
</script>

<style lang="css">
  @import "./assets/fonts/stylesheet.css";

  .route-comefunziona #onetrust-consent-sdk {
    display: none !important;
  }

  .route-notfound #onetrust-consent-sdk {
    display: none !important;
  }
</style>

<style lang="scss">
  // @import url('https://use.typekit.net/rjh2mos.css');
  @import "@/assets/scss/_mixins.scss";
  @import "@/assets/scss/app.scss";
</style>
