export default {
  'value is not a valid email address': 'e-mail non valida',
  'The user with this username does not exist in the system.': 'Un utente con questo indirizzo non esiste',
  'Incorrect email or password': 'Mail e/o password errate',
  'Invalid password. Use 6 or more valid char': 'Password non valida. Inserire almeno 6 caratteri.',
  'The user with this username already exists in the system': 'Un utente con questo indirizzo email già esiste',
  'A user with this email already exists in the system': 'Un utente con questo indirizzo email già esiste',
  'Point of sale does not exist in the system.': 'Il Punto Vendita selezionato non esiste',
  'Token does not exist in the system.': 'Il Codice Inserito non è valido',
  'Token already been played.': 'Il codice inserito è già stato utilizzato',
  'field required': 'Campo richiesto',
  'Invalid token': 'Accesso non valido',
  'Person not of age': 'Per partecipare devi essere maggiorenne.',
  'value is not a valid integer': 'Valore non valido',
  'Email not valid': 'E-mail non valida',
  'string does not match regex "^[A-Za-z]+[A-Za-z ]*$"': 'Nome autore / nome fragranza non valido (caratteri speciali presenti)',
  'Inactive user': 'Utente non confermato',
  'This item already exists in the system': 'Codice documento già utilizzato',
  'bad word': 'I campi nome fragranza e/o autore contengono termini o caratteri speciali non consentiti',
  'Item_number invalid': 'Numero polizza non valido',
  'User does not have frontend privileges': 'Utente non abilitato alla normale autenticazione',
  'Fiscal code not valid or person not of age': 'Codice fiscale non valido / appartenente ad un minorenne',
  'A user with this fiscal code already exists': 'Un utente con questo codice fiscale già esiste',
  'The item with this number already exists in the system': 'Una polizza con questo codice già esiste',
  'First name is too long or contains invalid char': 'Nome troppo lungo o con caratteri non validi',
  'Last name is too long or contains invalid char': 'Cognome troppo lungo o con caratteri non validi',
  'none is not an allowed value': 'Selezionare un valore valido',
  'Phone number invalid or too long (max 15 char)': 'Numero di telefono non valido o troppo lungo (max 15 caratteri)',
  'Already voted': 'Hai già espresso il tuo voto',
  'The user with this first_name, last_name, birth_date, birth_city already exists in the system': 'Un utente con questo nome, cognome, data di nascita e comune di nascita già esiste',
  'Item number invalid': 'Numero scontrino non valido',
  'Item date not valid': 'Data documento non valida (valide dal 1 giugno 2022 fino ad oggi)',
  'Item value invalid': 'Importo scontrino non valido',
  'The user with this phone number already exists in the system': 'Un utente con questo numero di telefono è già stato registrato.',
  'The user with this phone_number already exists in the system': 'Un utente con questo numero di telefono è già stato registrato.',
  'terms_accepted must be true': 'Devi accettare i Termini & le Condizioni del concorso',
  'privacy1_accepted must be true': 'Devi accettare la Privacy Policy del concorso',
  'Invalid Loyalty Card': 'Carta Fedeltà non valida',
  'PlayableCode does not exist in the system.': 'Il codice inserito non è valido.',
  'PlayedCode already been played.': 'Il codice inserito è già stato utilizzato.',
  'Already partecipated today': 'Oggi hai già partecipato 5 volte. Ritenta domani',
  'str type expected': 'Si necessita l\'inserimento di una stringa testuale',
  'This email already exists in the system': 'Questo indirizzo già esiste nel sistema.',
  'IBAN not valid Invalid checksum digits': 'IBAN non valido.'
}
