import { createRouter, createWebHistory } from 'vue-router'
import * as Sentry from '@sentry/vue'
import store from '@/store'
import NotFound from '@/views/404.vue'
import { updateBodyClass } from '@/utils/updateBodyClass'
const baseTitle = 'Genertellife Cashback'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: baseTitle + ' | Homepage'
    }
  },
  // {
  //   path: '/cookies',
  //   component: () => import('@/views/Cookie.vue')
  // },
  {
    path: '/come-funziona',
    name: 'ComeFunziona',
    component: () => import('@/views/Info.vue')
  },
  // {
  // {
  //   path: '/email-confirm',
  //   name: 'Confirm',
  //   component: () => import('@/views/Confirm.vue'),
  //   meta: {
  //     title: baseTitle + ' | User confirmation'
  //   }
  // },
  // {
  //   path: '/reset-password',
  //   component: () => import('@/views/Reset.vue')
  // },
  // {
  //   path: '/recovery',
  //   component: () => import('@/views/Recovery.vue')
  // },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  Sentry.configureScope((scope) => scope.setTransactionName(to?.meta?.label))
  await store.dispatch('initializeStore')
  const loggedIn = store.state.user
  // redirect to Home page if not logged in and trying to access a restricted page
  const publicPages = [
    '/login'
  ]
  const authRequired = publicPages.includes(to.path)

  // --- I AM THE ROUTER GUARD
  if (authRequired && !loggedIn) {
    return next('/')
  }

  // redirect to Home page if logged in and trying to access a not-logged-needed page
  const notLoggedPages = [
    '/email-confirm'
  ]
  const notLoggedRequired = notLoggedPages.includes(to.path)
  if (notLoggedRequired && loggedIn) {
    return next('/')
  }

  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = baseTitle
  }
  next()
})

router.afterEach((to) => {
  updateBodyClass(to)
})

export default router
