// ---IMPORT JS
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueScrollTo from 'vue-scrollto'
import VueConfetti from 'vue-confetti'
import Particles from 'particles.vue3'
import mitt from 'mitt'
import * as Sentry from '@sentry/vue'
import i18n from './utils/i18n'
import { BrowserTracing } from '@sentry/tracing'
import { OhVueIcon, addIcons } from 'oh-vue-icons'
import { BiActivity, RiLoaderFill, MdReportgmailerrorredRound } from 'oh-vue-icons/icons'

import './utils/vee-validate'
// ---IMPORT CSS
import './index.css'
// import './assets/css/tailwind.css'

// ---CONFIG
// * https://www.smashingmagazine.com/2020/10/authentication-in-vue-js/
axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL + '/api/v1'

// * https://oh-vue-icons.js.org/
addIcons(
  BiActivity,
  RiLoaderFill,
  MdReportgmailerrorredRound
)

const app = createApp(App)
// --Global Vars
// app.config.globalProperties.projectName = 'Promotica Control Panel'

// https://docs.sentry.io/platforms/javascript/guides/vue/#vue-3
Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'localhost:3000', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

app
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(Particles)
  .use(VueScrollTo)
  .use(VueConfetti)
  .use(i18n)
  .component('v-icon', OhVueIcon)

const emitter = mitt()
app.config.globalProperties.emitter = emitter

app
  .mount('#app') // ---FIRED UP, AND READY TO SERVE 🤖

// ---DEBUG .env
console.log(process.env.VUE_APP_NAME + ' - ' + process.env.NODE_ENV)
