import { createI18n } from 'vue-i18n'
import it from '@/lang/it'

const i18n = createI18n({
  locales: [
    // { code: 'en', file: 'en.js' },
    { code: 'it', file: 'it.js' }
  ],
  defaultLocale: 'it',
  lazy: true,
  langDir: 'lang/',
  legacy: false,
  globalInjection: true,
  fallbackLocale: 'it',
  messages: {
    it
  }
  // vueI18n: {
  //   fallbackLocale: 'en'
  //   // messages: {
  //   //  en: {
  //   //    welcome: 'Welcome'
  //   //  },
  //   //  it: {
  //   //    welcome: 'Benvenuto',
  //   //    'value is not a valid email address': 'e-mail non valida'
  //   //  }
  //   // }
  // }
})

export default i18n
