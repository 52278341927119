import {
  defineRule,
  configure
} from 'vee-validate'

import {
  email,
  confirmed,
  min,
  max
} from '@vee-validate/rules'

import { localize } from '@vee-validate/i18n'
import { checkEan } from '@/utils/ean13.js'

// ---VENDOR
defineRule('email', email)
defineRule('confirmed', confirmed)
defineRule('min', min)
defineRule('max', max)

// ---CUSTOM
defineRule('req', value => {
  if (!value || !value.length) {
    return 'Questo campo è obbligatorio.'
  }
  return true
})

defineRule('password', value => {
  if (value.length < 6) {
    return 'Lunghezza minima: 6 caratteri.'
  }
  return true
})

defineRule('phone', value => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }

  let trimmed = value.replace(/\s/g, '')
  trimmed = trimmed.replace('+', '')
  // Check if phone number
  if (isNaN(trimmed)) {
    return 'Numero di telefono non valido (esempio corretto: +39XXXXXXXXXX).'
  }
  return true
})

defineRule('ean13', value => {
  console.log('EANCHECK', value)
  if (!checkEan(value)) {
    return 'Carta inserita non valida.'
  }
  return true
})

// ---TRANSLATIONS
configure({
  // Italian message locale generator
  generateMessage: localize('it', {
    messages: {
      email: 'Indirizzo non valido.',
      confirmed: 'I due campi non coincidono.',
      min: 'Lunghezza minima non raggiunta.',
      max: 'Lunghezza massima superata.'
    }
  })
})
